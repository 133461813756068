import React from 'react';
import FloatingWhatsAppButton from '../../components/BotaoWhatsApp';
import Footer from '../../components/Footer';
import ContactForm from '../../components/FormContact/form';
import Header from '../../components/NavBar/nav';
import Services from '../../components/Services/services';
import Testimonials from '../../components/Testimonias/testimonials';
import './App.css';

function App() {
  return (
    <div>
      <Header />
      <div className="dentist-banner">
      <h2>Seu Sorriso é a Nossa Prioridade!</h2>
    </div>
      <Services />
      <Testimonials />
      <FloatingWhatsAppButton />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
