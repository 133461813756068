import React from 'react';
import WhatsappButton from '../ButtonAgend/Button';


const Services = () => {
  return (
    <section className="services">
       <p>Descubra o sorriso dos seus sonhos com a expertise cuidadosa e inovadora do nosso consultório odontológico. Sua saúde bucal em boas mãos, sorrisos transformadores começam aqui!</p>
      

      <WhatsappButton />
    </section>
  );
};

export default Services;
