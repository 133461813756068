import React from 'react';
import Card from '../Cards/Card'; // Certifique-se de ajustar o caminho do arquivo conforme necessário
import imagem03 from './imgs/clinico-geral-sorridents-3.webp';
import imagem01 from './imgs/estetica-sorridents-1.webp';
import imagem04 from './imgs/implantes-sorridents-4.webp';
import imagem06 from './imgs/odontopediatria-sorridents-6.webp';
import imagem02 from './imgs/ortodontis-sorridents-2.webp';
import imagem05 from './imgs/tratamentos-sorridents-4.webp';
import './testimunha.css'; // Arquivo CSS que conterá os estilos

const CardSection = () => {
  const cardsData = [
    // Adicione os dados para os seus seis cartões
    { titulo: 'Estética', imagemSrc: imagem01, descricao: 'Aqui você encontra nossos tratamentos estéticos que vão lhe garantir um sorriso mais bonito, brilhante e com muita autoestima', message: 'estética' },
    { titulo: 'Ortodontia', imagemSrc: imagem02, descricao: 'Encontre tratamentos ortodônticos que vão proporcionar uma mastigação adequada e um sorriso mais alinhado', message: 'ortodontia'  },
    { titulo: 'Clínico Geral', imagemSrc: imagem03, descricao: 'Descubra o que nossos tratamentos de limpeza, restauração e muito mais podem fazer pelo seu sorriso', message: 'clínico geral'},
    { titulo: 'Implantes', imagemSrc: imagem04, descricao: 'Perdeu um ou mais dos seus dentes naturais? Conheça nossos tratamentos de implantes dentários e recupere o seu sorriso', message: 'implantes'},
    { titulo: 'Outros Tratamentos', imagemSrc: imagem05, descricao: 'Encontre aqui os nossos melhores tratamentos odontológicos para você sorrir sem dor e sem receio', message: 'outros Tratamentos'},
    { titulo: 'Odontopediatria', imagemSrc: imagem06, descricao: 'Tratamentos específicos realizados por dentistas pediatras da melhor qualidade para garantir a saúde bucal do seu filho ', message: 'odontopediatria' },

    
    // ... adicione os dados para os outros cartões
  ];

  return (
    <section className="card-section">
      {cardsData.map((card, index) => (
        <Card key={index} titulo={card.titulo} imagemSrc={card.imagemSrc} descricao={card.descricao} message={card.message} />
      ))}
    </section>
  );
};

export default CardSection;

