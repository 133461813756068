import React from 'react';
import ContactNumbers from '../../enum/enum-phone';
import './cards.css';

const Card = (props) => {
  const { titulo, imagemSrc, descricao, message } = props;
  const numeroWhatsapp = ContactNumbers.WhatsApp;

  const mensagem = encodeURIComponent(`Queria mais informações sobre ${message}`);

  const urlWhatsapp = `https://api.whatsapp.com/send?phone=${numeroWhatsapp}&text=${mensagem}`;

  return (
    <div className="card">
      <img src={imagemSrc} alt={titulo} />
      <div className="card-content">
        <h2>{titulo}</h2>
        <p>{descricao}</p>

      <div className="container">
       <a href={urlWhatsapp} className="agendar-btn">Saiba mais</a>
     </div>
      </div>
    </div>
  );
};

export default Card;
