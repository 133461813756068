import React from 'react';

const MapComponent = () => {
  return (
    <div>
      <div className="map-container">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.9042878207897!2d-46.45270641684759!3d-23.607765308535598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce68b017766b59%3A0xbdcc350c247e9199!2sTv.%20Somos%20Todos%20Iguais%20-%20Jardim%20da%20Conquista%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2008343-000!5e0!3m2!1spt-BR!2sbr!4v1705800870871!5m2!1spt-BR!2sbr"
          width="300"
          height="250"
          style={{ border: '0' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default MapComponent;
