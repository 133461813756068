import React from 'react';
import { Link } from 'react-router-dom';
import facebook from './img/facebook.png';
import instagra from './img/instagram.png';
import "./styled.css";



export default function Redesociais(){
  return(
    <>
      <div className='logoTipos'>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <Link to='https://m.facebook.com/codsconsultorioodontologico'><img src={facebook} alt='facebook' /></Link>
            <Link to='https://www.instagram.com/cods.consultorio'><img src={instagra} alt="instagran" /></Link>
          </div>
    </>
  )
}