import React, { useState } from 'react';
import { BiMailSend, BiMessage, BiUser } from 'react-icons/bi';
import { sendEmail } from '../../api/sendEmail';
import Loader from '../Load/loader';

import './form.css';

const ContactForm = ({ icon, placeholder, ...props }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    motivo: '', // Adicionando campo 'motivo'
  });

  const [showLoader, setShowLoader] = useState(false)

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendEmail(formData, setFormData, setShowLoader);
  };



  return (
    <div>
      <form className='formContact' onSubmit={handleSubmit}>
        <h2 className='title'>Agende a sua avaliação!</h2>

        <div className='input-container'>
          <BiUser className='icon' />
          <input
            type="text"
            name="name"
            placeholder="Digite seu nome"
            value={formData.name}
            onChange={handleChange}
          />
        </div>

        <div className='input-container'>
          <BiMailSend className='icon' />
          <input
            type="email"
            name="email"
            placeholder="Seu E-mail:"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <br />

        <label>
          <BiMessage className='icon' />
          <textarea
            name="motivo"
            placeholder="Motivo Para contato:"
            value={formData.motivo}
            onChange={handleChange}
          />
        </label>
        <br />

        <div className="divFlex">
          <input checked type='checkbox' />
          <p>Aceito receber novidades conteúdos informativos e publicitários.</p>
        </div>
        <div className="divFlex">
          <input checked type='checkbox' />
          <p>Ao marcar, eu aceito a Política de Privacidade.</p>
        </div>

        <button type="submit">Enviar </button>

        <h2 className='callTitle'>AGENDE PELOS TELEFONES</h2>
        <p className='textCall'>Capitais e Regiões Metropolitanas</p>
        <h3>(11) 5050-7741</h3>
      </form>

      {showLoader && <Loader />}
    </div>
  );
};



export default ContactForm;
