import React from 'react';
import './Button.css'; // Certifique-se de criar um arquivo CSS para estilizar seu botão

const WhatsappButton = () => {
  const numeroWhatsapp = 'SEUNUMERO'; // Substitua pelo seu número de telefone

  const mensagem = encodeURIComponent('Quero agendar uma avaliação');

  const urlWhatsapp = `https://api.whatsapp.com/send?phone=${numeroWhatsapp}&text=${mensagem}`;

  return (
    <div className="container">
      <a href={urlWhatsapp} className="agendar-btn">AGENDAR UMA AVALIAÇÃO</a>
    </div>
  );
};

export default WhatsappButton;
