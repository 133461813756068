import axios from 'axios';

async function sendEmail(formData, setFormData, setShowLoader) {
  try {
    setShowLoader(true)
    await axios.post('https://lonely-blue-long-underwear.cyclic.app/send-email', formData);
    setFormData({ name: '', email: '', motivo: '' }); // Limpando os campos após o envio
  }finally {
    setShowLoader(false)
  }
}

export { sendEmail };
