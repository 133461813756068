import { FloatingWhatsApp } from "react-floating-whatsapp";
import ContactNumbers from '../../enum/enum-phone';
import logo from "../../img/logo.jpg";

export default function FloatingWhatsAppButton() {
  return (
    <FloatingWhatsApp
      phoneNumber={ContactNumbers.WhatsApp}
      accountName="CODS Consultório odontológico Dias e Sousa"
      statusMessage="atendimento e agilidade!"
      chatMessage="Olá Como podemos ajudar?"
      avatar={logo}
    />
  );
}
