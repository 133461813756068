import React from 'react';
import footer2 from '../../img/logo.jpg';
import MapComponent from '../Map/map';
import Redesociais from '../RedesSociais';
import "./styled.css";




export default function Footer() {
  return (
    <div className='footer'>
      <div className='navegation'>
        <h2>Nossa Localização</h2>
      <p>Travessa somos todos iguais N° 724</p>
      <p>São Paulo, JD da conquista, SP</p>
      <p>CEP: 08343-000</p>
      <p>Horário: 09:00 as 18:00 de Segunda a Sexta</p>
      <p>Sabado: 09:00 as 14:00</p>
       <MapComponent />
      </div>


      <div className='newslatter'>
       
        <div className='cnpjSection'>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img src={footer2} className='footer2' />
          <p className='paragrafo'>Copyright © 2021 CONSULTORIO ODONTOLOGICO DIAS E SOUSA LTDA

            CNPJ n.° 44.698.7/630.001-23 / Travessa somos todos iguais,
            N° 724, jd da conquista, São Paulo/SP -

            CEP 08343-000 - Consultorio odontologico Dias e Sousa LTDA.</p>

            <Redesociais />

        
        </div>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}

        <div className="rodape" >
          <p >
            O parceiro que anuncia nesta página é o único responsável pelas transações comerciais que realizar com usuários do web site CODS LABORATÓRIOS.
            A comercialização do produto anunciado, bem como a garantia de sua legítima procedência, é de inteira responsabilidade do anunciante.
            As informações.
          </p>
        </div>

      </div>
    </div>
  )
}