import { Component } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import About from "./Page/About/about";
import Contact from "./Page/Contact/contact";
import Home from "./Page/Home/App";
import NoPage from "./Page/NoPage";


class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Router>
    );
  }
}

export default App;